//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import ExporterForm from '@/modules/exporter/components/2-exporter-form';
import { routerAsync } from '@/app-module';
import i18n from '@/vueI18n';

export default {
  name: 'app-exporter-form-page',

  props: ['id'],

  components: {
    [ExporterForm.name]: ExporterForm,
  },

  data() {
    return {
      tab: 'branch',
    }
  },
  computed: {
    ...mapGetters({
      // record: 'exporter/form/record',
      // findLoading: 'exporter/form/findLoading',
      // saveLoading: 'exporter/form/saveLoading',
    }),
    findLoading() {
      return this.$store.getters[
        `${this.type}/form/findLoading`
      ];
    },
    saveLoading() {
      return this.$store.getters[
        `${this.type}/form/saveLoading`
      ];
    },
    isEditing() {
      return !!this.id;
    },
    language(){
      return i18n.locale
    }
  },
  watch: {
    language() {
               document.title =
            this.i18n('app.title') +
            ' | ' +
            this.i18n('entities.exporter.label')  +
            ' | ' +
            this.i18n('entities.exporter.new.title') 
    },
  },

  async created() {
    await this.doFetchSeller()
  },

  methods: {
    ...mapActions({
      doCreate: `exporter/form/doCreate`,
      doUpdate: `exporter/form/doUpdate`,
      doFetchSeller: 'seller/list/doFetch',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    // doUpdate(payload) {
    //   return this.$store.dispatch(
    //     `${this.tab}/form/doUpdate`, payload
    //   );
    // },
    doCancel() {
      routerAsync().push('/export');
    },

    async doSubmit(payload) {
     
      return this.doCreate(payload);
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { i18n } from '@/i18n';
import { mapActions, mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { ExporterModel } from '@/modules/exporter/exporter-model';
import { routerAsync } from '@/app-module';
import firebase from 'firebase/app'
import 'firebase/firestore'
// import ExporterErrorModal from '@/modules/exporter/components/exporter-error-modal';
// import ExporterSuccessfullyModal from '@/modules/exporter/components/exporter-successfully-modal';
// import ExporterFormStep3 from '@/modules/exporter/components/exporter-form-step3';
// import ExporterFormStep5 from '@/modules/exporter/components/exporter-form-step5';

const { fields } = ExporterModel; 
const formSchema = new FormSchema([
  fields.id,
  fields.itemId,
  fields.itemType,
  fields.serialNumber,
  fields.qrCode,
  fields.warrantyStatus,
  fields.status,
  fields.activationDate,
  fields.expirationDate,
  fields.activatedBy,
]);

export default {
  name: 'app-exporter-form',
  props: ['isEditing'],
  
  // components: {
  //   // [ExporterErrorModal.name]: ExporterErrorModal,
  //   // [ExporterSuccessfullyModal.name]: ExporterSuccessfullyModal,
  //   [ExporterFormStep3.name]: ExporterFormStep3,
  //   [ExporterFormStep5.name]: ExporterFormStep5,
  // },

  data() {
    return {
      step: 1,
      tab: 'branch',
      model: null,
      numberOfItems: undefined,
      active: false,
      sellerId: undefined,
      options: [],


      serialNumberTest: undefined,
      qrCodeTest: undefined,
      errorDialogVisible: false,
      errorModalVisible: false,
      successDialogVisible: false,
      successModalVisible: false,
      rules: formSchema.rules(),
      unsubscribe: undefined
    };
  },

  async created() {
    this.unsubscribe = firebase.firestore().collection('branch').onSnapshot(async querySnapshot => {
       console.log(`Received query snapshot of size ${querySnapshot.size}`);
      this.doFilterInBackground();
    }, err => {
       console.log(`Encountered error: ${err}`);
    });
    if (this.isEditing) {
      await this.doFind(this.id);
    } else {
      await this.doNew();
    }
    this.model = formSchema.initialValues(this.record || {});
  },
  beforeDestroy(){
    if(this.unsubscribe) this.unsubscribe()
  },
  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
      seller: 'seller/list/rows',
      branch: 'branch/list/rows',
      sellerLoading: 'seller/list/loading',
      branchLoading: 'branch/list/loading',
      
      record: 'exporter/form/record',
      findLoading: 'exporter/form/findLoading',
      saveLoading: 'exporter/form/saveLoading',
    }),
    loadingItems() {
      return this.sellerLoading
    },
    fields() {
      return fields;
    },
    type() {
      return this.tab
    },
    rows() {
      let list; //= this.seller.filter(el => el.sellerType == this.type)
      switch (this.tab) {
        case 'branch':
          list = this.branch
          break;
        case 'distributor':
          list = this.seller.filter(el => el.sellerType == this.type)
          break;
      } 
      return list
    },
    itemList() {
      return this.rows.map(item => { return { label: item.name, value: item.id } })
    }
  },

  methods: {
    ...mapActions({
      doFind: 'exporter/form/doFind',
      doNew: 'exporter/form/doNew',
      doCreate: `exporter/form/doCreate`,
      doUpdate: `exporter/form/doUpdate`,
      doFetchInBackground: 'branch/list/doFetchInBackground',
    }),
    async doFilterInBackground() {
      const filter = {}
      return this.doFetchInBackground({
        filter,
      });
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
    doReset() {
      this.model = formSchema.initialValues(this.record || {});
      this.model.warrantyStatus = 'inactive'
      this.model.status = 'inStock'
      this.numberOfItems = undefined;
    },
    
    doOpenModal(type) {
      switch (type) {
        case 'error':
          this.errorModalVisible = true
          this.errorDialogVisible = true;
          break;
        case 'success':
          this.successModalVisible = true
          this.successDialogVisible = true;
          break;
      }
    },
    onModalClose() {
      this.errorDialogVisible = false;
      this.successDialogVisible = false;
      setTimeout(() => {
        this.errorModalVisible = false
        this.successModalVisible = false
      }, 1000);
    },
    doTryAgain() {
      this.serialNumberTest = undefined;
      this.qrCodeTest = undefined;
      this.onModalClose()
    },
    async doNextItem() {
      if (this.numberOfItems > 0) {
        await this.doFetchSerial(1)
        this.model.serialNumber = this.serialNumbers[0].serialNumber
        this.numberOfItems-- 
        this.serialNumberTest = undefined;
        this.qrCodeTest = undefined;
        this.step = 2
      } else {
        routerAsync().push('/export');
      }
      this.onModalClose()
    },
    doClearInput(inputName) {
       console.log(inputName);
      switch (inputName) {
        case 'serialNumberTest':
          this.serialNumberTest = undefined
          break;
        case 'qrCodeTest':
          this.qrCodeTest = undefined
          break;
        default:
          break;
      }
    },
    OnItemChange() {
      let item = this.rows.filter(el => el.id == this.itemId) 
      this.model.qrCode = item.length ? item[0].qrCode : undefined;
    },
    OnClear() {
      this.model.qrCode = undefined
    },
    filterFn (val, update) {
      if (val === '') {
        update(() => {
          this.options = this.itemList
        })
        return
      }

      update(() => {
        const needle = val.toLowerCase()
        this.options = this.itemList.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
      })
    },
    
    prev() { this.step > 1 ? this.step-- : this.$emit('cancel') },
    
    async next() { 
      if (this.step == 1) {
        // await this.doFetchSerial(parseInt(this.numberOfItems))
        await this.doFetchSerial(1)
        this.model.serialNumber = this.serialNumbers[0].serialNumber
        this.numberOfItems-- 
      } else if (this.step == 4) {
        if (this.model.serialNumber != this.serialNumberTest && this.model.qrCode != this.qrCodeTest.toLowerCase()) {
          return this.doOpenModal('error')
        }
      }

      this.step++ 
    },
    
    doCancel() {
      this.$emit('cancel');
    },
    
    async doSubmit() {
      routerAsync().push(`/export/${this.sellerId}/scan`);
    },
  },
  watch: {
    sellerId() {
      if (this.sellerId) {
        // let item_selected = this.rows.filter(el => el.id == this.itemId)[0]
        this.model.sellerId = this.sellerId
      } else {
        this.model.sellerId = undefined
      }
    },
    // tab() {
    //   this.model.itemId = undefined
    //   this.itemId = undefined
    //   this.numberOfItems = undefined
    //   this.model.itemType = this.tab
    // }
  },
};
